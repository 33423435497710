#circle{
    width:"450px";
    height:"450px";
    color:red;
    background:linear-gradient(to bottom, #fa39ad 50%,#fe6c4c 50%);
    filter:blur(100px)
}
@keyframes slide {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-30%);
    }
}
.logo-slide{
    animation: 10s slide infinite linear;
}